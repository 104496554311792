// Listen for wsInvListingShown events that fire before the API is loaded,
// so the API can replay the events when it's ready.
window.DDC.APIEvents = {
	received: [],
	handleShown: data => {
		if (data) {
			window.DDC.APIEvents.received.push(data);
		}
	},
	start: () => {
		window.addEventListener(
			'wsInvListingShown',
			window.DDC.APIEvents.handleShown
		);
	},
	get: () => {
		return window.DDC.APIEvents.received;
	}
};
window.DDC.APIEvents.start();

const APILoader = class APILoader {
	isReady() {
		return new Promise(resolve => {
			if (window.DDC.API) {
				resolve(true);
			} else {
				window.addEventListener('WIAPIReady', () => {
					resolve(true);
				});
			}
		});
	}

	static async create(init = 'default-integration-id') {
		const apiLoader = new APILoader();
		await apiLoader.isReady();
		return new window.DDC.API(init);
	}
};

window.DDC.APILoader = APILoader;
